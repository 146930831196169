import axios from "axios";
import { useEffect, useState } from "react";

import { useDispatch } from 'react-redux';
import { setUser } from '../../slices/user';

import { useNavigate } from "react-router-dom";

const Login = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [alias, setAlias] = useState('');
    const [passw, setPassw] = useState('');     

    const login = (evt) => {
        evt.preventDefault();
        let data = {
            alias: alias,
            password: passw,
            source: 1
        };
        axios.post('https://be.lincloud.it/user/login', data)
            .then(function (response) {
                if (response.data.code) {
                    console.log(response.data.result);
                } else {
                    dispatch(setUser({
                        anony: false,
                        token: response.data.result.token,
                        alias: alias,
                    }));
                    navigate('/profile');
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    let jsx;
    if (props.fullPage) {
        jsx = (
            <div className="container col-xxl-8 px-4 py-5 h-100 d-flex justify-content-center text-center">
                <form className="form-signin w-100 m-auto" onSubmit={login}>
                    <img className="mb-2" src="/images/logo/LL-cfblu.png" alt="" width="72" height="57" />
                    <h1 className="h3 mb-4 fw-normal">
                        <span style={{ fontFamily: 'Playfair Display', fontStyle: 'italic', color: 'cornflowerblue' }}>Lovely Legacy</span>
                    </h1>

                    <div className="form-floating">
                        <input
                            type="text"
                            className="form-control"
                            id="floatingInput"
                            placeholder="Nome Utente"
                            required
                            value={alias}
                            onChange={(evt) => setAlias(evt.target.value)} />
                        <label htmlFor="floatingInput">Nome Utente</label>
                    </div>
                    <div className="form-floating">
                        <input
                            type="password"
                            className="form-control"
                            id="floatingPassword"
                            placeholder="Password"
                            required
                            value={passw}
                            onChange={(evt) => setPassw(evt.target.value)} />
                        <label htmlFor="floatingPassword">Password</label>
                    </div>

                    <div className="checkbox mb-3">
                    </div>
                    <button className="w-100 btn btn-lg btn-primary" type="submit">Accedi</button>
                </form>
            </div>
        );
    } else {
        jsx = (
            <form onSubmit={login}>
                <div className="form-floating mb-3">
                    <input
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        placeholder="Nome Utente"
                        required
                        value={alias}
                        onChange={(evt) => setAlias(evt.target.value)} />
                    <label htmlFor="floatingInput">Nome Utente</label>
                </div>
                <div className="form-floating mb-3">
                    <input
                        type="password"
                        className="form-control"
                        id="floatingPassword"
                        placeholder="Password"
                        required
                        value={passw}
                        onChange={(evt) => setPassw(evt.target.value)} />
                    <label htmlFor="floatingPassword">Password</label>
                </div>

                <div className="checkbox mb-3">
                </div>
                <button className="w-100 btn btn-lg btn-primary" type="submit">Accedi</button>
            </form>
        );
    }

    return jsx;
}

export default Login;