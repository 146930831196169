import { Component } from "react";
import { Link } from "react-router-dom";

class Footer extends Component {
    render() {
        return (
            <footer className="footer mt-auto pt-5" style={{ backgroundColor: '#ddd' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-6 col-sm-6 col-md-3 mb-3">
                            <h5>Section</h5>
                            <ul className="nav flex-column">
                                <li className="nav-item mb-2"><Link to="/" className="nav-link p-0 text-muted">Home</Link></li>
                                <li className="nav-item mb-2"><Link to="/pricing" className="nav-link p-0 text-muted">Pricing</Link></li>
                                <li className="nav-item mb-2"><Link to="/" className="nav-link p-0 text-muted">FAQs</Link></li>
                            </ul>
                        </div>

                        <div className="col-6 col-md-3 mb-3">
                            <h5>Section</h5>
                            <ul className="nav flex-column">
                                <li className="nav-item mb-2"><Link to="/" className="nav-link p-0 text-muted">Features</Link></li>
                                <li className="nav-item mb-2"><Link to="/" className="nav-link p-0 text-muted">FAQs</Link></li>
                                <li className="nav-item mb-2"><Link to="/" className="nav-link p-0 text-muted">About</Link></li>
                            </ul>
                        </div>

                        <div className="col-md-5 offset-md-1 mb-3">
                            <form>
                                <h5>Subscribe to our newsletter</h5>
                                <p>Monthly digest of what's new and exciting from us.</p>
                                <div className="d-flex flex-column flex-sm-row w-100 gap-2">
                                    <label htmlFor="newsletter1" className="visually-hidden">Email address</label>
                                    <input id="newsletter1" type="text" className="form-control" placeholder="Email address" />
                                    <button className="btn btn-primary" type="button">Subscribe</button>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="d-flex flex-column flex-sm-row justify-content-between pb-2 pt-4 my-2" style={{ borderTop: '1px solid #aaa' }}>
                        <p><img src='/images/logo/LL-black.png' alt="LL" style={{ width: '2.2rem', marginTop: '-.66rem' }} /><span style={{ fontFamily: 'Playfair Display', fontStyle: 'italic', fontSize: '1.5rem', marginLeft: '1rem', marginRight: '1rem' }}>Lovely Legacy</span>© 2022 Company, Inc. All rights reserved.</p>
                        <ul className="list-unstyled d-flex">
                            <li className="ms-3"><Link className="link-dark" to="/"><i className="fa-brands fa-twitter fa-xl"></i></Link></li>
                            <li className="ms-3"><Link className="link-dark" to="/"><i className="fa-brands fa-instagram fa-xl"></i></Link></li>
                            <li className="ms-3"><Link className="link-dark" to="/"><i className="fa-brands fa-facebook fa-xl"></i></Link></li>
                        </ul>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer;