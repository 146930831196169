import { configureStore } from '@reduxjs/toolkit';
import counterSlice from './slices/counterSlice';
import toastingSlice from './slices/toasting';
import userSlice from './slices/user';

export default configureStore({
    reducer: {
        counter: counterSlice,
        user: userSlice,
        toasting: toastingSlice,
    }
});