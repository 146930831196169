import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        isAnonymous: true,
        token: '',
        alias: ''
    },
    reducers: {
        setUser: (state, action) => {
            state.isAnonymous = action.payload.anony;
            state.token = action.payload.token;
            state.alias = action.payload.alias;
            document.cookie = `alias=${state.alias};`;
            document.cookie = `token=${state.token};`;
            if (action.payload.anony) {
                document.cookie = `expires=${new Date(0).toUTCString()};`;
            } else {
                let now = new Date();
                let minutesFromMS = 60 * 60 * 1000;
                now.setTime(now.getTime() + minutesFromMS);
                document.cookie = `expires=${now.toUTCString()};`;
            }
        },
        checkCookie: (state, action) => {
            //if (state.isAnonymous) {
            if (getCookie('expires') !== null) {
                if (Date.parse(getCookie('expires')) !== 0) {
                    if (isCookieExpired(Date.parse(getCookie('expires')))) {
                        state.isAnonymous = true;
                        state.token = '';
                        state.alias = '';

                        document.cookie = `alias=;`;
                        document.cookie = `token=;`;
                        document.cookie = `expires=${new Date(0).toUTCString()};`;
                        action.payload = {
                            session: 'expired'
                        }
                    } else {
                        state.isAnonymous = false;
                        state.token = getCookie('token');
                        state.alias = getCookie('alias');
                    }
                }
            }
            //}
        }
    },
});

const isCookieExpired = (cookieDate) => {
    let now = new Date().getTime();
    return new Date(cookieDate).getTime() < now;
}
const getCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1);
        if (c.indexOf(nameEQ) !== -1) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

export const { setUser, checkCookie } = userSlice.actions;

export default userSlice.reducer;