import axios from "axios";
import { useEffect, useState } from "react";

import { useSelector } from 'react-redux';

const Profile = () => {
    const [user, setUser] = useState({});
    const token = useSelector((state) => state.user.token)
    const alias = useSelector((state) => state.user.alias)

    const [buttonText, setButtonText] = useState('Add other info');

    let data = {
        alias: alias
    };

    useEffect(() => {
        if (token !== '') {
            console.log("-------------------");
            axios.post('https://be.lincloud.it/user/info', data, {
                headers: { 'authorization': '1_' + token }
            })
                .then(function (response) {
                    console.log(response.data.result);                
                    if (response.data.code) {
                        alert(response.data);
                    } else {
                        setUser(JSON.parse(response.data.result));
                    }
                }).catch(function (error) {
                    console.log(error);
                });
        }
    }, [token]);

    const changeButtonText = () => {
        // if(user.phone !== '' && user.residency !== '' && user.birthday !== '' && user.birthplace !== ''){
        //     setButtonText('Modifica');
        // }else{
        //     setButtonText('Add other info');
        // }
        setButtonText('Modifica');
    };

    return (
        <section className="mt-5">
            <div className="container py-5">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="card mb-4" style={{ backgroundColor: '#fafafa' }}>
                            <div className="card-body text-center">
                                <i className="fa-solid fa-circle-user" style={{ fontSize: '10rem' }}></i>
                                <h5 className="my-3">{user.name}</h5>
                                <p className="text-muted mb-1">{user.alias}</p>
                                <p className="text-muted mb-4">{user.residency}</p>
                                <div className="d-flex justify-content-center mb-2">
                                    <button type="button" className="btn btn-primary">Follow</button>
                                    <button type="button" className="btn btn-outline-primary ms-1">Message</button>
                                </div>
                            </div>
                        </div> 
                    </div>
                    <div className="col-lg-8 card py-3" style={{ backgroundColor: '#fafafa' }}>
                        <div className="card mb-4">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-3">
                                        <p className="mb-0">Full Name</p>
                                    </div>
                                    <div className="col-sm-9">
                                        <p className="text-muted mb-0">{user.name} {user.surname}</p>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-sm-3">
                                        <p className="mb-0">Email</p>
                                    </div>
                                    <div className="col-sm-9">
                                        <p className="text-muted mb-0">{user.email}</p>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-sm-3">
                                        <p className="mb-0">Phone</p>
                                    </div>
                                    <div className="col-sm-9">
                                        <p className="text-muted mb-0">{user.phone}</p>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-sm-3">
                                        <p className="mb-0">Residency</p>
                                    </div>
                                    <div className="col-sm-9">
                                        <p className="text-muted mb-0">{user.residency}</p>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-sm-3">
                                        <p className="mb-0">Birthday</p>
                                    </div>
                                    <div className="col-sm-9">
                                        <p className="text-muted mb-0">{user.birthday}</p>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-sm-3">
                                        <p className="mb-0">Birthplace</p>
                                    </div>
                                    <div className="col-sm-9">
                                        <p className="text-muted mb-0">{user.birthplace}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center mb-2">
                                <a href="https://web.lincloud.it/addOtherInfo" class="btn btn-primary" onClick={changeButtonText}>{buttonText}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Profile;