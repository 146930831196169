import './App.css';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Header from './components/layout/header';
import Footer from './components/layout/footer';
import Home from './components/page/home';
import Login from './components/page/login';
import Register from './components/page/register';
import Pricing from './components/page/pricing';
import AddOtherInfo from './components/page/addOtherInfo'
import Session from './components/layout/session';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Profile from './components/page/profile';

import { useSelector } from 'react-redux';

const App = () => {
  const isAnonymous = useSelector((state) => state.user.isAnonymous);

  const routes = isAnonymous ? (<>
    <Route path="/" element={<Home />}></Route>
    <Route path="/login" element={<Login fullPage={true} />}></Route>
    <Route path="/register" element={<Register />}></Route>
    <Route path="/pricing" element={<Pricing />}></Route>
  </>) : (<>
    <Route path="/" element={<Home />}></Route>
    <Route path="/pricing" element={<Pricing />}></Route>
    <Route path="/profile" element={<Profile />}></Route>
    <Route path="/addOtherInfo" element={<AddOtherInfo />}></Route>
  </>);

  return (
    <BrowserRouter>
      <Header />
      <main className="my-auto">
        <Routes>
          {routes}
        </Routes>
      </main>

      <Footer />
      <ToastContainer
        theme='light'
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Session />
    </BrowserRouter>
  );
}

export default App;