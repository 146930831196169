import { useSelector, useDispatch } from 'react-redux'
import { checkCookie } from '../../slices/user';

import { setToast } from '../../slices/toasting';

import { toast } from 'react-toastify';

import { /*useLocation,*/ useNavigate } from "react-router-dom";
import { useEffect } from 'react';

const Session = () => {
    // const location = useLocation();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const message = useSelector((state) => state.toasting.message);
    const aKind = useSelector((state) => state.toasting.aKind);

    useEffect(() => {
        let cookieRes = dispatch(checkCookie())
        if (cookieRes.payload !== undefined) {
            if (cookieRes.payload.session === 'expired') {
                dispatch(setToast({ message: 'Sessione Scaduta', aKind: 3 }));
                navigate('/login');
            }
        }
    });

    useEffect(() => {
        if (message !== '') {
            switch (aKind) {
                case 1:
                    toast.success(message);
                    break;
                case 2:
                    toast.info(message);
                    break;
                case 3:
                    toast.warn(message);
                    break;
                case 4:
                    toast.error(message);
                    break;
                default:
            }
        }
    }, [message, aKind]);

    return (<></>);
}
export default Session;