import { createSlice } from '@reduxjs/toolkit';

export const toastingSlice = createSlice({
    name: 'toasting',
    initialState: {
        message: '',
        aKind: 0
    },
    reducers: {
        setToast: (state, action) => {
            state.message = action.payload.message;
            state.aKind = action.payload.aKind;
        }
    },
});

export const { setToast } = toastingSlice.actions;

export default toastingSlice.reducer;