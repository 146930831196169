import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from '../../slices/user';
import { useNavigate } from "react-router-dom";

const Header = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isAnonymous = useSelector((state) => state.user.isAnonymous)
    const alias = useSelector((state) => state.user.alias)
    const location = useLocation();

    const logoutHandler = () => {
        console.log('asd');
        dispatch(setUser({
            anony: true,
            token: '',
            alias: '',
        }));
        navigate('/login');
    }

    let headerLinks;
    if (isAnonymous) {
        headerLinks =
            <ul className="navbar-nav ms-auto mb-2 mb-md-0">
                <li className="nav-item"><Link className={location.pathname === '/pricing' ? "nav-link active" : "nav-link"} to="/pricing">Prezzi</Link></li>
                <li className="nav-item"><Link className={location.pathname === '/register' ? "nav-link active" : "nav-link"} to="/register">Registrati</Link></li>
                <li className="nav-item"><Link className={location.pathname === '/login' ? "nav-link active" : "nav-link"} to="/login">Accedi</Link></li>
            </ul>;
    } else {
        headerLinks =
            <ul className="navbar-nav ms-auto mb-2 mb-md-0">
                <li className="nav-item"><Link className={location.pathname === '/pricing' ? "nav-link active" : "nav-link"} to="/pricing">Prezzi</Link></li>
                <li className="nav-item"><Link className={location.pathname === '/profile' ? "nav-link active" : "nav-link"} to="/profile">{alias.charAt(0).toUpperCase() + alias.slice(1)}</Link></li>
                <li className="nav-item"><span className="nav-link" style={{ cursor: 'pointer' }} onClick={logoutHandler}>Esci</span></li>
            </ul>;
    }

    return (<header>
        <nav className="navbar navbar-expand-md navbar-dark fixed-top" style={{ backgroundColor: 'cornflowerblue' }}>
            <div className="container-fluid">
                <Link className="navbar-brand" to="/">
                    <img src='/images/logo/LL-white.png' alt='LL' style={{ width: '2.2rem', marginTop: '-.66rem' }} /><span className='d-none d-md-inline-block' style={{ fontFamily: 'Playfair Display', fontStyle: 'italic', fontSize: '1.5rem', marginLeft: '1rem' }}>Lovely Legacy</span>
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">{headerLinks}</div>
            </div>
        </nav>
    </header>);

}

export default Header;