import axios from "axios";
import { useDispatch } from 'react-redux';
import { setToast } from '../../slices/toasting';
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const Register = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [alias, setAlias] = useState('');
    const [password, setPassword] = useState('');
    const [password_confirm, setPassword_confirm] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [birthday, setBirthday] = useState('');
    const [birthplace, setBirthplace] = useState('');
    const [residency, setResidency] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');

    const onChangePassword = () => {
        const password = document.querySelector('input[id=password]');
        const confirm = document.querySelector('input[id=password_confirm]');
        if (password.value.length < 8) {
            password.setCustomValidity('La password deve avere almeno 8 caratteri');
        } else {
            password.setCustomValidity('');
            if (confirm.value === password.value) {
                confirm.setCustomValidity('');
            } else {
                confirm.setCustomValidity('Le password non corrispondono.');
            }
        }
    }

    const register = (evt) => {
        evt.preventDefault();
        let data = {
            alias: alias,
            password: password,
            password_confirm: password_confirm,
            name: name,
            surname: surname,
            birthday: birthday,
            birthplace: birthplace,
            residency: residency,
            email: email,
            phone: phone
        };
        axios.post('https://be.lincloud.it/user/register', data)
            .then(function (response) {                
                if (response.data.code) {
                    console.log(response.data.result);
                } else {      
                    console.log(response.data);              
                    dispatch(setToast({ message: 'Registrazione Completata', aKind: 1 }));
                    navigate('/login');
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <div className="container col-xxl-8 px-4 py-5 h-100 d-flex justify-content-center text-center">
            <form className="form-signin w-100 m-auto" onSubmit={register}>
                <img className="mb-2" src="/images/logo/LL-cfblu.png" alt="" width="72" height="57" />
                <h1 className="h3 mb-4 fw-normal">
                    <span style={{ fontFamily: 'Playfair Display', fontStyle: 'italic', color: 'cornflowerblue' }}>Lovely Legacy</span>
                </h1>

                <div className="form-floating text-start">
                    <h5 style={{ fontVariant: 'small-caps' }}>Dati Utente</h5>
                </div>
                {/*name*/}
                <div className="form-floating">
                    <input type="text" className="form-control" id="name" placeholder="Nome" required value={name} onChange={(evt) => setName(evt.target.value)} />
                    <label htmlFor="name">Nome</label>
                </div>
                {/*surname*/}
                <div className="form-floating">
                    <input type="text" className="form-control" id="surname" placeholder="Cognome" required value={surname} onChange={(evt) => setSurname(evt.target.value)} />
                    <label htmlFor="surname">Cognome</label>
                </div>
                {/*alias*/}
                <div className="form-floating">
                    <input type="text" className="form-control" id="alias" placeholder="Nome Utente" required value={alias} onChange={(evt) => setAlias(evt.target.value)} />
                    <label htmlFor="alias">Nome Utente</label>
                </div>
                {/*email*/}
                <div className="form-floating">
                    <input type="email" className="form-control" id="email" placeholder="Email" required value={email} onChange={(evt) => setEmail(evt.target.value)} />
                    <label htmlFor="email">Email</label>
                </div>                
                {/*password*/}
                <div className="form-floating">
                    <input type="password" className="form-control" id="password" placeholder="Password" required value={password} onChange={(evt) => { setPassword(evt.target.value); onChangePassword(); }} />
                    <label htmlFor="password">Password</label>
                </div>
                {/*password_confirm*/}
                <div className="form-floating">
                    <input type="password" className="form-control" id="password_confirm" placeholder="Conferma Password" required value={password_confirm} onChange={(evt) => { setPassword_confirm(evt.target.value); onChangePassword(); }} />
                    <label htmlFor="password_confirm">Conferma Password</label>
                </div>
                {/*{/*phone}
                <div className="form-floating">
                    <input type="text" className="form-control" id="phone" placeholder="Telefono" required value={phone} onChange={(evt) => setPhone(evt.target.value)} />
                    <label htmlFor="phone">Telefono</label>
                </div>
                <div className="form-floating text-start mt-5">
                    <h5 style={{ fontVariant: 'small-caps' }}>Dati Anagrafici</h5>
                </div>                
                {/*birthday}
                <div className="form-floating">
                    <input type="date" className="form-control" id="birthday" placeholder="Data di Nascita" required value={birthday} onChange={(evt) => setBirthday(evt.target.value)} />
                    <label htmlFor="birthday">Data di Nascita</label>
                </div>
                {/*birthplace}
                <div className="form-floating">
                    <input type="text" className="form-control" id="birthplace" placeholder="Comune di Nascita" required value={birthplace} onChange={(evt) => setBirthplace(evt.target.value)} />
                    <label htmlFor="birthplace">Comune di Nascita</label>
                </div>
                {/*residency}
                <div className="form-floating">
                    <input type="text" className="form-control" id="residency" placeholder="Comune di Residenza" required value={residency} onChange={(evt) => setResidency(evt.target.value)} />
                    <label htmlFor="residency">Comune di Residenza</label>
                </div>*/}

                <div className="checkbox mb-3">
                </div>
                <button className="w-100 btn btn-lg btn-primary">Registrati</button>
            </form>
        </div>
    );
}

export default Register;