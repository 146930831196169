function Divider(props) {
    return (<div className='ex-divider'>
        <div className='trans-gradient'></div>
        <svg className="editorial" xmlns="http://www.w3.org/2000/svg" viewBox="0 24 150 28 " preserveAspectRatio="none">
            <defs>
                <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g className="parallax2"><use href="#gentle-wave" x="50" y="0" fill="#c1d5f8" className={props.evenOdd ? 'delay1' : ''} /></g>
            <g className="parallax1"><use href="#gentle-wave" x="50" y="3" fill="#e0eafb" className={props.evenOdd ? 'delay2' : ''} /></g>
            <g className="parallax3"><use href="#gentle-wave" x="50" y="9" fill="#93b5f2" className={props.evenOdd ? 'delay1' : ''} /></g>
            <g className="parallax4"><use href="#gentle-wave" x="50" y="6" fill="#6495ed" className={props.evenOdd ? 'delay2' : ''} /></g>
        </svg>
    </div>);
}

export default Divider;