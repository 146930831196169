import { Link } from "react-router-dom";
import Divider from "../static/divider";
import Login from "./login";
import { useSelector } from 'react-redux';

const Home = () => {

    const isAnonymous = useSelector((state) => state.user.isAnonymous);

    const anonLoginForm = isAnonymous ? (
        <>
            <Divider evenOdd={false} />
            <div className="container col-xl-10 col-xxl-8 px-4 py-5">
                <div className="row align-items-center g-lg-5 py-5">
                    <div className="col-lg-7 text-center text-lg-start">
                        <h1 className="display-4 fw-bold lh-1 mb-3">Aenean sed hendrerit lacus</h1>
                        <p className="col-lg-10 fs-4">Fusce ornare, turpis vel eleifend sagittis, dolor velit faucibus neque, vitae rhoncus mi lectus consequat nisl. Nunc elementum velit a dui interdum.</p>
                    </div>
                    <div className="col-md-10 mx-auto col-lg-5">
                        <div className="p-4 p-md-5 border rounded-3 bg-light">
                            <Login fullPage={false} />
                            <hr className="my-4" />
                            <small className="text-muted">Ti serve un account? <Link to='/register'>registrati qui</Link>.</small>
                        </div>
                    </div>
                </div>
            </div>
        </>
    ) :
        (<></>);
    return (<>
        <div className="container col-xxl-8 px-4 py-5">
            <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
                <div className="col-10 col-sm-8 col-lg-6">
                    <img src="https://getbootstrap.com/docs/5.2/examples/heroes/bootstrap-themes.png" className="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" />
                </div>
                <div className="col-lg-6">
                    <h1 className="display-5 fw-bold lh-1 mb-3">Lorem ipsum dolor sit amet</h1>
                    <p className="lead">consectetur adipiscing elit. Nullam ultricies faucibus dolor, non pellentesque mauris semper finibus. Suspendisse potenti. Vestibulum in arcu quis est pellentesque ultrices at a tortor.</p>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                        <button type="button" className="btn btn-primary btn-lg px-4 me-md-2">Primary</button>
                        <button type="button" className="btn btn-outline-secondary btn-lg px-4">Default</button>
                    </div>
                </div>
            </div>
        </div>

        {anonLoginForm}

        <Divider evenOdd={true} />

        <div className="container px-4 py-5" id="hanging-icons">
            <h2 className="pb-2 border-bottom">Hanging icons</h2>
            <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
                <div className="col d-flex align-items-start">
                    <div className="icon-square text-bg-light d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3 mt-3">
                        <i className="fa-solid fa-sliders fa-xl"></i>
                    </div>
                    <div>
                        <h2>Featured title</h2>
                        <p>Paragraph of text beneath the heading to explain the heading. We'll add onto it with another sentence and probably just keep going until we run out of words.</p>
                        <p className='text-end'><Link to="/" className="icon-link d-inline-flex align-items-center">Call to action&nbsp;<i className="fa-solid fa-chevron-right"></i></Link></p>
                    </div>
                </div>
                <div className="col d-flex align-items-start">
                    <div className="icon-square text-bg-light d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3 mt-3">
                        <i className="fa-solid fa-mobile-screen fa-xl"></i>
                    </div>
                    <div>
                        <h2>Featured title</h2>
                        <p>Paragraph of text beneath the heading to explain the heading. We'll add onto it with another sentence and probably just keep going until we run out of words.</p>
                        <p className='text-end'><Link to="/" className="icon-link d-inline-flex align-items-center">Call to action&nbsp;<i className="fa-solid fa-chevron-right"></i></Link></p>
                    </div>
                </div>
                <div className="col d-flex align-items-start">
                    <div className="icon-square text-bg-light d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3 mt-3">
                        <i className="fa-solid fa-shield-heart fa-xl"></i>
                    </div>
                    <div>
                        <h2>Featured title</h2>
                        <p>Paragraph of text beneath the heading to explain the heading. We'll add onto it with another sentence and probably just keep going until we run out of words.</p>
                        <p className='text-end'><Link to="/" className="icon-link d-inline-flex align-items-center">Call to action&nbsp;<i className="fa-solid fa-chevron-right"></i></Link></p>
                    </div>
                </div>
                <div className="col d-flex align-items-start">
                    <div className="icon-square text-bg-light d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3 mt-3">
                        <i className="fa-solid fa-layer-group fa-xl"></i>
                    </div>
                    <div>
                        <h2>Featured title</h2>
                        <p>Paragraph of text beneath the heading to explain the heading. We'll add onto it with another sentence and probably just keep going until we run out of words.</p>
                        <p className='text-end'><Link to="/" className="icon-link d-inline-flex align-items-center">Call to action&nbsp;<i className="fa-solid fa-chevron-right"></i></Link></p>
                    </div>
                </div>
                <div className="col d-flex align-items-start">
                    <div className="icon-square text-bg-light d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3 mt-3">
                        <i className="fa-solid fa-circle-user fa-xl"></i>
                    </div>
                    <div>
                        <h2>Featured title</h2>
                        <p>Paragraph of text beneath the heading to explain the heading. We'll add onto it with another sentence and probably just keep going until we run out of words.</p>
                        <p className='text-end'><Link to="/" className="icon-link d-inline-flex align-items-center">Call to action&nbsp;<i className="fa-solid fa-chevron-right"></i></Link></p>
                    </div>
                </div>
                <div className="col d-flex align-items-start">
                    <div className="icon-square text-bg-light d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3 mt-3">
                        <i className="fa-solid fa-photo-film fa-xl"></i>
                    </div>
                    <div>
                        <h2>Featured title</h2>
                        <p>Paragraph of text beneath the heading to explain the heading. We'll add onto it with another sentence and probably just keep going until we run out of words.</p>
                        <p className='text-end'><Link to="/" className="icon-link d-inline-flex align-items-center">Call to action&nbsp;<i className="fa-solid fa-chevron-right"></i></Link></p>
                    </div>
                </div>
            </div>
        </div>

        <Divider evenOdd={false} />

        <div className="container">
            <h1 className="mt-5">Sticky footer with fixed navbar</h1>
            <p className="lead">Pin a footer to the bottom of the viewport in desktop browsers with this custom HTML and CSS. A fixed navbar has been added with</p>
            <code className="small"><pre>padding-top: 60px;</pre></code>
            <p className="lead">on the</p>
            <code className="small"><pre>main &gt; .container</pre></code>
            <p>Back to <Link to="/">the default sticky footer</Link> minus the navbar.</p>
        </div>
    </>);
}

export default Home;