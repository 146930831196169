import axios from "axios";
import { useDispatch } from 'react-redux';
import { setToast } from '../../slices/toasting';
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';

const AddOtherInfo = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const token = useSelector((state) => state.user.token)
    const alias = useSelector((state) => state.user.alias)

    //const [user, setUser] = useState({});
    const [birthday, setBirthday] = useState('');
    const [birthplace, setBirthplace] = useState('');
    const [residency, setResidency] = useState('');
    const [phone, setPhone] = useState('');

    const add = (evt) => {
        evt.preventDefault();
        console.log("safasdf" + token);
        if (token !== '') {
            let data = {
                alias: alias,
                birthday: birthday,
                birthplace: birthplace,
                residency: residency,
                phone: phone
            };
            axios.post('https://be.lincloud.it/user/addOtherInfo', data, {
                headers: { 'authorization': '1_' + token }
            })    
                .then(function (response) {  
                    console.log(response.data);         
                    if (response.data.code) {
                        alert(response.data);
                    } else { 
                        //setUser(JSON.parse(response.data.result));
                        dispatch(setToast({ message: 'Dati aggiunti correttamente', aKind: 1 }));
                        navigate('/profile');
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    return (
        <div className="container col-xxl-8 px-4 py-5 h-100 d-flex justify-content-center text-center">
            <form className="form-signin w-100 m-auto" style={{ paddingTop: "3rem" }} onSubmit={add}>
                <div className="form-floating text-start">
                    <h5 style={{ fontVariant: 'small-caps' }}>Dati Utente</h5>
                </div>
                <div className="form-floating">
                    <input type="date" className="form-control" id="birthday" placeholder="Data di Nascita" required value={birthday} onChange={(evt) => setBirthday(evt.target.value)} />
                    <label htmlFor="birthday">Data di Nascita</label>
                </div>
                {/*birthplace*/}
                <div className="form-floating">
                    <input type="text" className="form-control" id="birthplace" placeholder="Comune di Nascita" required value={birthplace} onChange={(evt) => setBirthplace(evt.target.value)} />
                    <label htmlFor="birthplace">Comune di Nascita</label>
                </div>
                {/*residency*/}
                <div className="form-floating">
                    <input type="text" className="form-control" id="residency" placeholder="Comune di Residenza" required value={residency} onChange={(evt) => setResidency(evt.target.value)} />
                    <label htmlFor="residency">Comune di Residenza</label>
                </div>
                {/*phone*/}
                <div className="form-floating">
                    <input type="text" className="form-control" id="phone" placeholder="Telefono" required value={phone} onChange={(evt) => setPhone(evt.target.value)} />
                    <label htmlFor="phone">Telefono</label>
                </div>

                <div className="checkbox mb-3"></div>
                <button className="w-100 btn btn-lg btn-primary">Add</button>
            </form>
        </div>
    );
}

export default AddOtherInfo;